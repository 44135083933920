<template>
  <v-container>
    <v-row no-gutters class="my-3 justify-center justify-sm-start">
      <v-col
        v-for="(product, i) in products"
        :key="i"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        class="pa-6 pa-sm-5 pa-md-5 d-flex justify-center"
      >
        <div class="d-flex justify-center">
          <Card
            :card="product"
            :type="
              product.productType === 'Servicio'
                ? 'ProviderServicesDetails'
                : 'ProviderPackagesDetails'
            "
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Card from "@/components/Shared/Card.vue";
export default {
  components: {
    Card,
  },
  props: {
    products: { type: Array, required: true },
    filterResult: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss"></style>
