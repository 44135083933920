<template>
  <div>
    <v-data-table
      :filter-result="filterResult"
      :headers="headers"
      :items="dataList"
      item-key="uniqueId"
      :header-props="{
        'sort-by-text': 'Ordenar por',
      }"
      :loading="loading"
      loading-text="Cargando tabla..."
      hide-default-footer
      disable-pagination
    >
      <v-progress-linear
        v-show="loading"
        slot="progress"
        height="3px"
        color="secondary"
        indeterminate
      ></v-progress-linear>
      <template v-slot:[`item.name`]="{ item }">
        <p color="transparent" class="mx-1 my-3 text-h4">
          {{ item.name }}
        </p>
      </template>
      <template v-slot:[`item.productType`]="{ item }">
        <p color="transparent" class="mx-1 my-3 text-h4">
          {{ item.productType }}
        </p>
      </template>
      <template v-slot:[`item.category`]="{ item }">
        <p color="transparent" class="mx-1 my-3 text-h4">
          {{ item.category }}
        </p>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span color="transparent" class="mx-1 my-3 text-h4">
          {{ convertUnixToDDMMYYYY(item.date) }}
        </span>
      </template>
      <template v-slot:[`item.details`]="{ item }">
        <div class="ma-0 mt-2 mb-4 ma-sm-2">
          <Button
            text="Ver detalles"
            outlined
            aria-label="Ir a detalles del producto"
            :url="getRoute(item).name"
            :url-params="getRoute(item).params"
          />
        </div>
      </template>
      <template v-slot:no-data>No hay productos publicados</template>
    </v-data-table>
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
import { convertUnixToDDMMYYYY } from "@/Utils/dateConverter";

export default {
  components: {
    Button,
  },
  props: {
    dataList: { type: Array, required: true },
    filterResult: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    convertUnixToDDMMYYYY: convertUnixToDDMMYYYY,
  }),
  computed: {
    headers() {
      return [
        {},
        {
          text: "Nombre",
          value: "name",
          class: "text-h3 black--text font-weight-bold",
        },
        {
          text: "Tipo de producto",
          value: "productType",
          class: "text-h3 black--text font-weight-bold",
        },
        {
          text: "Categoría",
          value: "category",
          class: "text-h3 black--text font-weight-bold",
        },
        {
          text: "Fecha de publicación",
          value: "date",
          class: "text-h3 black--text font-weight-bold",
        },
        { value: "details", sortable: false },
      ];
    },
  },
  methods: {
    getRoute(item) {
      return {
        name:
          item.productType === "Servicio"
            ? "ProviderServicesDetails"
            : item.productType === "Paquete"
            ? "ProviderPackagesDetails"
            : "UserMainPage",
        params: {
          id: item.id,
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
.td-font {
  font-size: 1.05rem !important;
}
::v-deep .v-data-table-header tr {
  white-space: nowrap !important;
}
</style>
