<template>
  <v-container fluid ma-0 pa-0>
    <Navbar page="provider" />
    <v-container class="px-6 px-md-auto">
      <div class="text-h1 font-weight-bold mt-16 pt-md-3">Mis productos</div>
      <div class="d-flex flex-column flex-md-row justify-md-end my-8 my-md-5">
        <Button
          text="+ Publicar nuevo"
          aria-label="Ir al registro de servicio"
          url="CreateProduct"
        />
      </div>
      <!-- <v-tabs
        v-model="filterResult"
        color="secondary"
        background-color="transparent"
        show-arrows
        slider-size="5"
      >
        <v-tabs-slider> </v-tabs-slider>
        <v-tab
          v-for="state in tabsOptions"
          :key="state.key"
          class="btn-background-transparent text-capitalize black--text text-h5 px-3 px-sm-6"
          :ripple="false"
          :active-class="'font-weight-bold'"
        >
          {{ state.name }}
        </v-tab>
        <v-spacer></v-spacer>
      </v-tabs> -->
      <v-divider color="secondary" />
      <div class="d-flex flex-wrap mt-8 mb-10">
        <div class="d-flex align-center flex-wrap">
          <Button
            text="Todos"
            outlined
            pill
            :active="activeFilter === 'products'"
            class="mr-2 mb-3 mb-md-0"
            aria-label="Mostrar todos los productos"
            @event="handleActiveFilter('products')"
          />
          <Button
            text="Por servicio"
            outlined
            pill
            :active="activeFilter === 'services'"
            class="mr-2 mb-3 mb-md-0"
            aria-label="Mostrar productos filtrados por servicio"
            @event="handleActiveFilter('services')"
          />
          <Button
            text="Por paquete"
            outlined
            pill
            :active="activeFilter === 'packages'"
            class="mr-2 mb-3 mb-md-0"
            aria-label="Mostrar productos filtrados por paquete"
            @event="handleActiveFilter('packages')"
          />
        </div>
        <v-spacer />
        <div class="d-flex align-center">
          <v-sheet
            height="45"
            width="45"
            rounded
            class="tab-box d-flex align-center pa-2 mb-3 mb-md-0 mr-3"
            @click="setActiveTab('list')"
          >
            <v-img :src="activeTab === 'list' ? ListIcon : ListGrayIcon" />
          </v-sheet>
          <v-sheet
            height="45"
            width="45"
            rounded
            class="tab-box d-flex align-center mb-3 mb-md-0 pa-2"
            @click="setActiveTab('grid')"
          >
            <v-img :src="activeTab === 'grid' ? GridIcon : GridGrayIcon" />
          </v-sheet>
        </div>
      </div>
      <div v-if="activeTab === 'list'">
        <ProductsTable
          :data-list="filteredList"
          :filer-result="filterResult"
          :loading="loading"
        />
      </div>
      <div v-else-if="activeTab === 'grid'">
        <GridTable :products="filteredList" :filer-result="filterResult" />
      </div>
    </v-container>
    <Footer class="mt-16" />
  </v-container>
</template>

<script>
import ListIcon from "@/assets/icons/providers/List.svg";
import ListGrayIcon from "@/assets/icons/providers/ListGray.svg";
import GridIcon from "@/assets/icons/shared/Grid.svg";
import GridGrayIcon from "@/assets/icons/shared/GridGray.svg";
import Navbar from "@/components/Provider/Shared/Navbar.vue";
import Footer from "@/components/Shared/Footer.vue";
import Button from "@/components/Shared/Button.vue";
import ProductsTable from "@/components/Provider/Products/ProductsTable.vue";
import GridTable from "@/components/Provider/Products/GridTable.vue";
import { convertDateToCstTimeZoneUnix } from "@/Utils/dateConverter.js";
import { mergeProductsLists } from "@/Utils/Utils.js";
import { GET_PROVIDER_PRODUCTS } from "@/graphql/queries";
import { useQuery } from "@/graphql/index";
import { mapState } from "vuex";

export default {
  components: {
    Navbar,
    Footer,
    Button,
    ProductsTable,
    GridTable,
  },
  data() {
    return {
      ListIcon: ListIcon,
      ListGrayIcon: ListGrayIcon,
      GridIcon: GridIcon,
      GridGrayIcon: GridGrayIcon,
      packagesList: [],
      servicesList: [],
      productsList: [],
      activeTab: "list",
      tabsOptions: [
        { name: "Activos", value: "active" },
        {
          name: "Desactivados",
          value: "disable",
        },
      ],
      activeFilter: "products",
      filterResult: 0,
      searchSelect: ["Mas reciente", "Menos reciente"],
      loading: true,
    };
  },
  computed: {
    filterStatus() {
      return this.servicesList.filter((service) => {
        const serviceStatus = service.status.toLowerCase();
        let buttonValue = "";
        if (this.filterResult === 0) {
          buttonValue = "active";
        } else if (this.filterResult === 1) {
          buttonValue = "disable";
        }
        return serviceStatus.includes(buttonValue);
      });
    },
    filteredList() {
      switch (this.activeFilter) {
        case "products":
          return this.productsList;
        case "services":
          return this.servicesList;
        case "packages":
          return this.packagesList;
        default:
          return this.productsList;
      }
    },
    ...mapState({
      provider(state) {
        return state.provider;
      },
    }),
  },
  watch: {
    provider: {
      async handler(provider) {
        if (!provider || !provider.id) return;
        this.fetchProducts(provider.id);
      },
      immediate: true,
    },
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    async fetchProducts(providerId) {
      const { data, errors } = await useQuery(GET_PROVIDER_PRODUCTS, {
        providerId: parseInt(providerId),
      });
      if (data) {
        this.servicesList = this.formatServices(data.provider.service);
        this.packagesList = this.formatPackages(data.providerPackages);
        this.productsList = mergeProductsLists(
          this.servicesList,
          this.packagesList
        );
      } else if (errors) {
        console.log(errors);
      }
      this.loading = false;
    },
    formatServices(services) {
      const data = services.map((service) => {
        const info = {
          ...service,
          providerName: this.provider.name,
          rating:
            service.serviceComment.reduce(
              (total, currentValue) => total + currentValue.score,
              0
            ) / service.serviceComment.length,
          reviews: service.serviceComment.length,
          date: convertDateToCstTimeZoneUnix(service.insertedAt),
          productType: "Servicio",
        };
        return info;
      });
      return data;
    },
    formatPackages(packages) {
      const data = packages.map((pkg) => {
        const info = {
          ...pkg,
          providerName: this.provider.name,
          rating: 0,
          reviews: 0,
          category: pkg.type,
          date: convertDateToCstTimeZoneUnix(pkg.insertedAt),
          productType: "Paquete",
        };
        return info;
      });
      return data;
    },
    handleActiveFilter(filter) {
      this.activeFilter = filter;
    },
  },
};
</script>

<style scoped lang="scss">
.purple-gradient {
  background-color: rgba(98, 37, 130, 1);
}

.btn-background-transparent::before {
  background-color: transparent !important;
}

.tab-box {
  cursor: pointer;
  border: 1px solid var(--v-secondary-base);
  &:hover {
    background-color: rgba(98, 37, 130, 0.1);
  }
}
</style>
