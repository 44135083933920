var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"filter-result":_vm.filterResult,"headers":_vm.headers,"items":_vm.dataList,"item-key":"uniqueId","header-props":{
      'sort-by-text': 'Ordenar por',
    },"loading":_vm.loading,"loading-text":"Cargando tabla...","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('p',{staticClass:"mx-1 my-3 text-h4",attrs:{"color":"transparent"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.productType",fn:function(ref){
    var item = ref.item;
return [_c('p',{staticClass:"mx-1 my-3 text-h4",attrs:{"color":"transparent"}},[_vm._v(" "+_vm._s(item.productType)+" ")])]}},{key:"item.category",fn:function(ref){
    var item = ref.item;
return [_c('p',{staticClass:"mx-1 my-3 text-h4",attrs:{"color":"transparent"}},[_vm._v(" "+_vm._s(item.category)+" ")])]}},{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"mx-1 my-3 text-h4",attrs:{"color":"transparent"}},[_vm._v(" "+_vm._s(_vm.convertUnixToDDMMYYYY(item.date))+" ")])]}},{key:"item.details",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"ma-0 mt-2 mb-4 ma-sm-2"},[_c('Button',{attrs:{"text":"Ver detalles","outlined":"","aria-label":"Ir a detalles del producto","url":_vm.getRoute(item).name,"url-params":_vm.getRoute(item).params}})],1)]}},{key:"no-data",fn:function(){return [_vm._v("No hay productos publicados")]},proxy:true}],null,true)},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"slot":"progress","height":"3px","color":"secondary","indeterminate":""},slot:"progress"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }